import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import axios from './plugins/axios'
import print from 'vue3-print-nb'
import VueExcelEditor from 'vue3-excel-editor'

loadFonts()



const app = createApp(App)

axios.defaults.withCredentials = true;

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$excel = VueExcelEditor;
//GLOBAL VARS
app.config.globalProperties.$isNavVisible = true;
app.config.globalProperties.$dashboardLinks = [];


app.use(router)
  .use(vuetify)
  .use(print)
  .use(VueExcelEditor)
  .mount('#app')
