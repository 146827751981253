import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/informative/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/informative/AboutView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/RegistrationViewPatient.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/LoginView.vue')
  },
  {
    path: '/login/super',
    name: 'loginSuper',
    component: () => import('../views/pages/super/EmployeeLogin.vue')
  },
  {
    path: '/result/:id',
    component: () => import('../components/PrintableResult.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/pages/DashboardView.vue'),
    children: [
      {
        path: 'tests',
        component: () => import('../views/dashboard/UserTests.vue'),
      },

      {
        path: 'inventory',
        component: () => import('../components/super/InventorySummaryReport.vue'),
      },
      {
        path: 'book',
        component: () => import('../views/dashboard/BookAppointment.vue'),
      },
      {
        path: 'appointmentlists',
        component: () => import('../views/dashboard/AppointmentList.vue'),
      },
      {
        path: 'requests',
        component: () => import('../components/super/reports/CustomerRequests.vue'),
      },
      {
        path: 'testItems',
        component: () => import('../views/dashboard/TestList.vue'),
      },
      {
        path: 'admin/book',
        component: () => import('../views/dashboard/super/AddAppointment.vue'),
      },
      {
        path: 'admin/appointmentlists',
        component: () => import('../views/dashboard/super/AppointmentList.vue'),
      },
      {
        path: 'admin/materials',
        component: () => import('../views/dashboard/super/MaterialsList.vue'),
      },
      {
        path: 'admin/dashboard',
        component: () => import('../views/dashboard/super/DashBoardView.vue'),
      },
      {
        path: 'admin/used',
        component: () => import('../views/dashboard/super/UsedMapView.vue'),
      },
      {
        path: 'admin/reports',
        component: () => import('../views/dashboard/super/ReportsView.vue'),
      },
      {
        path: 'admin/users',
        component: () => import('../views/dashboard/super/UsersView.vue'),
      },
      //REPORTS
      {
        path: 'admin/customercatered',
        component: () => import('../components/super/reports/CustomersCatered.vue'),
      },
      {
        path: 'admin/inventoryperdepartment',
        component: () => import('../components/super/InventoryTallyPerDepartment.vue'),
      },
      {
        path: 'admin/referrals',
        component: () => import('../components/super/reports/ReferralReport.vue'),
      },
      {
        path: 'admin/inventorytally',
        component: () => import('../components/super/InventorySummaryReport.vue'),
      },
      {
        path: 'admin/expensesummary',
        component: () => import('../components/super/reports/ExpenseSummary.vue'),
      },

      // END OF REPORTS
      {
        path: 'admin/po',
        component: () => import('../views/dashboard/super/PoList.vue'),
      },
      {
        path: 'admin/doctors',
        component: () => import('../views/dashboard/super/DoctorsView.vue'),
      },
      {
        path: 'admin/tests',
        component: () => import('../views/dashboard/super/TestsView.vue'),
      },
      {
        path: 'admin/refundrequests',
        component: () => import('../views/dashboard/super/RefundRequests.vue'),
      },
      {
        path: 'admin/signatories',
        component: () => import('../views/dashboard/super/SignatoriesView.vue'),
      },
      {
        path: 'admin/templates',
        component: () => import('../views/dashboard/super/TemplatesView.vue'),
      },
      // CASHIER
      {
        path: 'cashier/paymenthistory',
        component: () => import('../views/dashboard/cashier/PaymentHistoryList.vue'),
      },
      {
        path: 'cashier/payments',
        component: () => import('../views/dashboard/cashier/PaymentsList.vue'),
      },
      {
        path: 'cashier/endofday',
        component: () => import('../views/dashboard/cashier/EndOfDayView.vue'),
      },
      //
      {
        path: 'lab/whatsup',
        component: () => import('../views/dashboard/lab/TodayBoard.vue'),
      },
      {
        path: 'lab/finished',
        component: () => import('../views/dashboard/lab/FinishedTestsView.vue'),
      },
      //RECEPTION
      {
        path: 'reception/whatsup',
        component: () => import('../views/dashboard/reception/TodayBoard.vue'),
      },
      {
        path: 'reception/book',
        component: () => import('../views/dashboard/super/AddAppointment.vue'),
      },
      {
        path: 'reception/profiles',
        component: () => import('../views/dashboard/reception/ProfilesView.vue'),
      },
      
      //DOCTOR
      {
        path: 'doctor/results',
        component: () => import('../views/dashboard/doctor/ResultsView.vue'),
      },
      //DOCTOR
      {
        path: 'doctor/results/:code',
        component: () => import('../views/dashboard/doctor/ResultsView.vue'),
        props: (router) => ({ code: router.params.code })
      },

    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
