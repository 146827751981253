// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const kdci = {
  dark: false,
  colors:{
    background: '#191c1d',
    surface: '#191c1d',
    'surface-variant': "#3f484b",
    primary: '#006879',
    'primary-darken-1': '#3700B3',
    secondary: '#4b6268',
    'secondary-darken-1': '#018786',
    'secondary-container': "#334a50",
    'tertiary': "#565d7e",
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    
  }
};
const kdci_light = {
  dark: false,
  colors:{
    background: '#fafcff',
    surface: '#fafcff',
    'surface-variant': "#3f484b",
    primary: '#006874',
    'primary-surface': '#3700B3',
    secondary: '#4c56af',
    'secondary-darken-1': '#018786',
    'secondary-container': "#334a50",
    'tertiary': "#565d7e",
    error: '#ba1a1a',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    
  }
};

export default createVuetify({
  theme: {
    defaultTheme: 'kdci_light',
    themes: {
      kdci,
      kdci_light
    }

  }
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
