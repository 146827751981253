<template>
  <v-app>
     <v-app-bar :hidden="isHidden" density="compact">
      <router-link to="/">Patients</router-link>
        <router-link to="/about">About</router-link>
      </v-app-bar>
    <router-view />
  </v-app>
  <SnackBar ref="SnackBar"></SnackBar>
</template>

<script>
import { getCurrentInstance } from '@vue/runtime-core'
import SnackBar from './components/shared/SnackBar.vue';
export default {
  components: {SnackBar},
  setup() {
    
  },
  mounted() {
      this.$root.SnackBar = this.$refs.SnackBar;
      document.title = "Welcome to KDCi!";
      
    },
  data(){
    return{
      isHidden: getCurrentInstance().appContext.config.globalProperties.$isNavVisible
    }
  }
}
</script>